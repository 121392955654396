export enum BookingStep {
  ACCOUNT_CREATION = "account_creation",
  ACCOUNT_SELECTION = "account_selection",
  APPOINTMENT_CONFIRMATION = "appt_confirmation",
  LOADING = "loading",
  LOCATION_SELECTION = "location_selection",
  LOGIN_BY_TOKEN_AND_BOOK = "login_by_token_and_book",
  REASON_SELECTION = "reason_selection",
  TIME_SELECTION = "time_selection",
  TIME_RESELECTION = "time_reselection", // NOT CONFIRMED YET w/ Hoda
  RESCHEDULING = "rescheduling",
  RESCHEDULING_CONFIRMATION = "rescheduling_confirmation",
}
